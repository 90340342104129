.progress-tracker {
  position: relative;
}

.progress-tracker:after {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: var(--color-gray);
  filter: brightness(60%);
  top: 0px;
  left: 0px;
}

.progress-tracker h3 {
  color: white;
  margin: 0;
  position: relative;
  z-index: 2;
  padding: 20px;
}

.progress-tracker__stage {
  max-width: 500px;
  width: 100%;
  position: relative;
  z-index: 2;
  margin: auto;
  display: flex;
  flex-direction: column;
}