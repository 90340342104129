.advert {
  background: linear-gradient(270deg, #00ABAC 0%, #55C2E5 100%);
  border-radius: 6px;
  padding: 22px;
  position: relative;
}

.advert__title {
  font-size: 25px;
  color: white;
  line-height: 1.5em;
  padding-right: 70px;
  margin: 0px;
}

.advert__ribbon {
  width: 150px;
  height: 150px;
  overflow: hidden;
  position: absolute;
  top: -10px;
  right: -10px;
}

.advert__ribbon::before,
.advert__ribbon::after {
  position: absolute;
  z-index: -1;
  content: '';
  display: block;
  border: 5px solid #000;
  border-top-color: transparent;
  border-right-color: transparent;
}

.advert__ribbon::before {
  top: 0;
  left: 0;
}

.advert__ribbon::after {
  bottom: 0;
  right: 0;
}

.advert__ribbon span {
  position: absolute;
  display: block;
  width: 225px;
  padding: 18px 0;
  background-color: #FAB54D;
  box-shadow: 0 5px 10px rgba(0,0,0,.1);
  color: #000;
  font: 700 12px/1 'Lato', sans-serif;
  text-transform: uppercase;
  text-align: center;
  left: -25px;
  top: 30px;
  transform: rotate(45deg);
}

.advert__image {
  width: 100%;
  margin: 15px 0;
}