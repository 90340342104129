.app-header {
  background-color: var(--color-gray);
}

.app-header > .wrapper {
  padding: 150px var(--horizontal-spacing) var(--horizontal-spacing);
  background-image: url(/public/header-bg.svg);
  background-repeat: no-repeat;
  background-position: center top -155px;
  background-size: 350px 300px;
}

.app-header p {
  color: white;
}

ul.color--orange {
  color: var(--color-orange) !important;
  list-style: none;
  padding: 0;
}

ul.color--orange li {
  margin: 5px 0;
}

.message .wrapper {
  padding: 15px;
}

.message--error {
  background-color: #e63660;
  text-align: center;
  color: white;
}

.message--error h3 {
  text-transform: uppercase;
  margin: 5px 0 0;
}

.message--error p {
  margin: 5px 0;  
}