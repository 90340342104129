@import-normalize;
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,900&display=swap');

:root {
  --color-green: #00ABAC; 
  --color-gray: #333E47;
  --color-red: #E63660;
  --color-orange: #FAB54D; 
  --horizontal-spacing: 15px;
}

html, body {
  background-color: var(--color-gray);
  margin: 0;
  height: 100%;
}

* {
  box-sizing: border-box;
  font-family: 'Lato';
}

a {
  color: var(--color-green);
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

#root {
  height: 100%;
}

.capitalize {
  text-transform: capitalize;
}

.wrapper {
  max-width: 600px;
  margin: auto;
}

.vendor-agency-details {
  display: flex;
  padding: 10px 0;
  align-items: center;
  justify-content: center;
}

.vendor-agency-details__left {
  flex-grow: 1;
  padding-right: 20px;
}

.vendor-agency-details__left p {
  margin: 0px;
}

.vendor-agency-details__right {
  text-align: right;
  display: flex;
  width: 80px;
}

.vendor-agency-details__right img {
  width: 100%;
}

.vendor-agency-details__center-content {
  flex-direction: column;
}

.vendor-agency-details__center-content .vendor-agency-details__left p {
  display: none;
}

.tabs__header {
  background-color: var(--color-gray);
}

.tabs__wrapper {
  display: flex;
  padding: 0 15px;
}

.tabs__tab {
  flex-grow: 1;
  padding: 13px 7px;
  background-color: var(--color-gray);
  border: 3px solid transparent;
  color: white;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  cursor: pointer;
  font-size: 13px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
  text-align: center;
  text-decoration: none;
}

.tabs__tab:not(.active):hover {
  border-bottom-color: var(--color-green);
}

.tabs__tab.active {
  cursor: default;
  background-color: rgba(0, 0, 0, 0.4);
}

.btn {
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  font-weight: 600;
  letter-spacing: 1px;
  display: block;
  width: 100%;
  padding: 15px;
  background-color: var(--color-gray);
  color: white;
  border-radius: 5px;
  cursor: pointer;
  border: none;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
}

.btn:hover {
  filter: brightness(110%);
  transform: scale(1.01);
}

.btn:active {
  filter: brightness(90%);
  transform: scale(0.98);
}

.btn--red {
  background-color: var(--color-red);
}

.btn--green {
  background-color: var(--color-green);
}

.slick-dots {
  position: relative !important;
  bottom: 0px !important;
}

.slick-arrow {
  display: none !important;
}
.slick-dots li {
  width: auto !important;
  width: auto !important;
}
.slick-dots li button {
  width: 8px !important;
  height: 8px !important;
  padding: 2px !important;
}
.slick-dots li button:before {
  font-size: 8px !important;
  line-height: normal !important;
}
.slick-dots li.slick-active button:before {
  color: var(--color-green) !important;
}

ul {
  padding-left: 20px;
}

ul li {
  margin: 10px 0;
}

.spinner-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner-wrapper p {
  color: var(--color-red);
  margin-left: 15px;
}

.spinner {
  animation: rotate 2s linear infinite;
  z-index: 2;
  position: relative;
  display: inline-block;
  width: 35px;
  height: 35px;
}

.spinner .path {
  stroke: #E63660;
  stroke-linecap: round;
  animation: dash 1.5s ease-in-out infinite;
}

@keyframes rotate {
  100% {
      transform: rotate(360deg)
  }
}

@keyframes dash {
  0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0
  }

  50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35
  }

  100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124
  }
}