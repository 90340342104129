.error-message__wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: white;
  text-align: center;
  padding: 20px;
}

.error-message {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: 100%;
}

.error-message__logo {
  width: 200px;
}

.error-message__wrapper .btn  {
  margin-top: 30px;
}