.photographer-info {
  padding: 5px 15px;
  background: rgba(51, 62, 72, 0.04);
  border-radius: 5px;
  border: 1px solid #E2E2E2;
}

.photographer-info__row {
  display: flex;
  align-items: center;
  margin: 10px 0;
}

.photographer-info__details p, 
.photographer-info__details h3 {
  margin: 0;
}

.photographer-info__company {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.5)
}

.photographer-info__name {
  font-size: 21px;
  margin: 5px 0 !important;
}

.photographer-info__website {
  font-size: 12px;
  text-transform: uppercase;
  color: #00ABAC;
  font-weight: bold;
}

.photographer-info__avatar {
  width: 75px;
  height: 75px;
  overflow: hidden;
  border-radius: 65px;
  margin-right: 25px;
}

.photographer-info__rating p,
.photographer-info__contact p {
  margin: 0;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 0.05em;
  font-weight: 700;
  width: 100px;
}

.photographer-info__call-now {
  display: block;
  margin: 15px 0 10px;
}

.rating-star {
  padding: 0px !important;
}

.rating-star g {
  stroke: transparent;
}