
.progress-timeline {
  background-color: white;
}

.progress-timeline__stage {
  position: relative;
  padding-left: 45px;
  background-color: rgb(247 247 247);
  z-index: 2;
  pointer-events: none;
  opacity: 1;
}

.progress-timeline__stage:before {
  content: '';
  width: 2px;
  background-color: var(--color-gray);
  position: absolute;
  left: 0px;
  top: 25px;
  height: 100%;
  display: none;
}

.progress-timeline__stage:after {
  content: '';
  width: 21px;
  height: 21px;
  border: 2px solid transparent;
  background-color: var(--color-gray);
  position: absolute;
  left: -12px;
  top: 25px;
  border-radius: 50%;
  display: none;
}

.progress-timeline__stage--complete,
.progress-timeline__stage--current {
  pointer-events: all;
  background-color: transparent;
  z-index: 1;
  padding-left: 23px;
  margin-left: 23px;
}

.progress-timeline__stage--complete:after,
.progress-timeline__stage--complete:before,
.progress-timeline__stage--current:after,
.progress-timeline__stage--current:before {
  display: block;
}

.progress-timeline__stage--complete:after,
.progress-timeline__stage--complete:before,
.progress-timeline__stage--current:after,
.progress-timeline__stage--current:before {
  background-color: var(--color-green);
}

.progress-timeline__stage--complete:after,
.progress-timeline__stage--current:after {
  background-image: url(/public/complete-stage-icon.svg);
  background-repeat: no-repeat;
  background-position: center;
}

.progress-timeline__stage-header {
  position: relative;
  display: block;
  background-color: transparent;
  border: none;
  width: 100%;
  font-size: 18px;
  font-weight: 800;
  text-align: left;
  cursor: pointer;
  padding: 25px 40px 25px 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.progress-timeline__stage-header:after {
  content: '';
  position: absolute;
  background-image: url(/public/chevron-right.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 24px;
  right: 10px;
  top: 50%;
  margin-top: -12px;
  width: 24px;
  height: 25px;
  transition: transform 0.3s cubic-bezier(0.34, 1.56, 0.64, 1);
  opacity: 0.3;
}

.progress-timeline__stage-header-dot {
  color: var(--color-red);
}

.progress-timeline__stage-header-title{
  opacity: 0.3;
  display: flex;
  justify-content: space-between;
}

.progress-timeline__stage-header-tags {
  display: inline-block;
  text-align: center;
}

.progress-timeline__stage-header-tags span {
  display: inline-block;
  font-size: 11px;
  background-color: rgba(0, 171, 172, 8%);
  color: #00ABAC;
  padding: 5px 7px;
  border-radius: 50px;
  font-weight: 500;
  margin-left: 10px;
}

.progress-timeline__stage--current .progress-timeline__stage-header-title,
.progress-timeline__stage--complete .progress-timeline__stage-header-title,
.progress-timeline__stage--current .progress-timeline__stage-header:after,
.progress-timeline__stage--complete .progress-timeline__stage-header:after,
.progress-timeline__stage--current .progress-timeline__stage-header-dot,
.progress-timeline__stage--complete .progress-timeline__stage-header-dot {
  opacity: 1;
}

.progress-timeline__stage-header-title-text {
  color: #333E47;
  flex-grow: 1;
}

.progress-timeline__stage-header-description {
  margin-top: 8px;
  font-size: 13px;
  font-weight: 500;
  line-height: 1.3em;
  display: block;
  color: #aaa;
}

.progress-timeline__stage--current .progress-timeline__stage-header-description,
.progress-timeline__stage--complete .progress-timeline__stage-header-description {
  display: none;
}

.progress-timeline__stage-body {
  padding-right: var(--horizontal-spacing); 
  padding-bottom: 25px;
} 

.progress-timeline__stage-body h4 {
  color: var(--color-green);
  text-transform: uppercase;
  margin-bottom: 10px;
}

.progress-timeline__stage-body h4:first-child {
  margin-top: 0px;
}

.progress-timeline__stage-body p {
  margin: 10px 0 0 0;
  line-height: 1.7em;
}

.progress-timeline__stage-body p:first-of-type {
  margin: 0;
}

.progress-timeline__stage.expanded .progress-timeline__stage-header:after {
  transform: rotate(90deg);
}
