.app-footer {
  background-color: var(--color-gray);
  padding: 10px 20px;
  color: white;
  position: relative;
  z-index: 2;
}

.feedback-section {
  text-align: center;
  padding: 15px;
  border-top:1px solid rgba(255, 255, 255, 0.2);
  margin-top: 10px;
}